import React, { useContext, useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Collapse,
    Box,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Autocomplete,
    Avatar,
    Tooltip,
    IconButton,
    Divider,
    Select,
    MenuItem,
} from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { Countries } from '../../Assets/countries';
import { getUserData } from '../../Assets/Utils';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import { Add, Edit, Delete } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import BadgeIcon from '@mui/icons-material/Badge';
import Loader from '../../Assets/Components/Loader';
import { AlertContext } from '../../Assets/Components/Alerter';
const TeamManagement = () => {
    const [teams, setTeams] = useState([]);
    const [filteredTeams, setfilteredTeams] = useState([])
    const [selectedTeamCategory, setSelectedTeamCategory] = useState(0)
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamName, setTeamName] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [isDeleting, setisDeleting] = useState(false)
    const [isLeaving, setisLeaving] = useState(false)
    const [isSaving, setisSaving] = useState(false)
    const userData = getUserData()
    const { setAlert } = useContext(AlertContext)






    useEffect(() => {
        setisLoading(true)
        axios.get(process.env.REACT_APP_BACKEND_API + "/getUsers").then((response) => {
            setUsers(response.data.data);
            fetchTeams();
        }).catch((err) => {
            setAlert(err, false)
        }).finally(() => {
            setisLoading(false)
        })
    }, []);

    useEffect(() => {
        if (selectedTeamCategory == 0) {
            setfilteredTeams(teams)
        } else if (selectedTeamCategory == 1) {
            setfilteredTeams(teams.filter(team => team.owner._id == userData._id))
        } else {
            setfilteredTeams(teams.filter(team => team.owner._id != userData._id))
        }
    }, [teams, selectedTeamCategory])


    const handleToggle = (teamId) => {
        setOpen((prevOpen) => ({ ...prevOpen, [teamId]: !prevOpen[teamId] }));
    };

    const fetchTeams = () => {
        axios.get(process.env.REACT_APP_BACKEND_API + "/getUserTeams").then((response) => {
            setTeams(response.data.data)
        }).catch((err) => {
            setAlert(err, false)
        })
    };





    const handleSaveTeam = () => {
        setisSaving(true)
        const url = selectedTeam
            ? process.env.REACT_APP_BACKEND_API + "/editUserTeam"
            : process.env.REACT_APP_BACKEND_API + "/addUserTeam";

        const data = selectedTeam
            ? { teamId: selectedTeam._id, name: teamName, members: selectedMembers }
            : { name: teamName, members: selectedMembers };

        axios.post(url, data).then(() => {
            fetchTeams();
            handleDialogClose();
        }).catch((err) => {
            setAlert(err, false)
        }).finally(() => {
            setisSaving(false)
        })
    };

    const handleDeleteTeam = (teamId) => {
        setisDeleting(true)
        axios.post(process.env.REACT_APP_BACKEND_API + "/removeUserTeam", { teamId }).then(() => {
            fetchTeams();
        }).catch((err) => {
            setAlert(err, false)
        }).finally(() => {
            setisDeleting(false)
        })
    };

    function handleLeaveTeam(teamId) {
        setisLeaving(true)
        axios.post(process.env.REACT_APP_BACKEND_API + "/leaveTeam", { teamId }).then(() => {
            fetchTeams()
        }).catch((err) => {
            setAlert(err, false)
        }).finally(() => {
            setisLeaving(false)
        })
    }


    const handleDialogOpen = (team = null) => {
        setSelectedTeam(team)
        setTeamName(team ? team.name : '')
        setSelectedMembers(team ? team.members.map(member => member._id) : [])
        setDialogOpen(true)
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Loader isLoading={isLoading}>
            <div className='container'>
                <div className='text primary xxlarge'>Team Management</div>
                <div className='card-container'>
                    <div className='text primary large'>Select Team Category</div>
                    <Select
                        value={selectedTeamCategory}
                        onChange={(e) => setSelectedTeamCategory(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value={0}>
                            All Teams
                        </MenuItem>
                        <MenuItem value={1}>
                            Your Teams
                        </MenuItem>
                        <MenuItem value={2}>
                            Joined Teams
                        </MenuItem>
                    </Select>

                    <Divider className='divider primary medium' />

                    <Button variant="contained" className='button primary medium' onClick={() => handleDialogOpen()}>
                        <Add /> Add Team
                    </Button>
                </div>



                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><div className='text secondary bold medium'>Team Name</div></TableCell>
                                <TableCell align="right"><div className='text secondary bold medium'>Members Count</div></TableCell>
                                <TableCell align="right"><div className='text secondary bold medium'>Actions</div></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTeams.map((team) => (
                                <React.Fragment key={team._id}>
                                    <TableRow
                                        onClick={() => handleToggle(team._id)}
                                        style={{ cursor: 'pointer' }}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "#bbb",
                                                transition: "0.3s",
                                            },
                                            transition: "0.5s",
                                        }}
                                    >
                                        <TableCell>
                                            <div className='text primary bold'>{team.name}</div>
                                        </TableCell>
                                        <TableCell align="right">{team.members.length}</TableCell>
                                        <TableCell align="right">
                                            {team.members.find(member => member._id == userData._id) &&
                                                <Tooltip title="Leave" >
                                                    <IconButton className='button primary' disabled={isLeaving} onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleLeaveTeam(team._id)
                                                    }}>
                                                        <LogoutIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {team.owner._id == userData._id &&
                                                <>
                                                    <Tooltip title="Manage" sx={{ ml: 1 }}>
                                                        <IconButton className='button secondary' onClick={(e) => {
                                                            e.stopPropagation()
                                                            handleDialogOpen(team)
                                                        }}>
                                                            <ManageAccountsIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" sx={{ ml: 1 }}>
                                                        <IconButton className='button error' disabled={isDeleting} onClick={(e) => {
                                                            e.stopPropagation()
                                                            handleDeleteTeam(team._id)
                                                        }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <Collapse in={open[team._id]} timeout="auto" unmountOnExit>
                                                <Box margin={1}>
                                                    <div className='text primary bold xlarge'>Members</div>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><div className='text secondary bold medium'>User</div></TableCell>
                                                                <TableCell><div className='text secondary bold medium'>First Name</div></TableCell>
                                                                <TableCell><div className='text secondary bold medium'>Last Name</div></TableCell>
                                                                <TableCell><div className='text secondary bold medium'>Email</div></TableCell>
                                                                <TableCell><div className='text secondary bold medium'>Country</div></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {team.members.map((member) => (
                                                                <TableRow key={member.username}>
                                                                    <TableCell>
                                                                        <Avatar alt="Avatar" src={(member.avatar ? member.avatar : member.first_name)} />
                                                                    </TableCell>
                                                                    <TableCell>{member.first_name}</TableCell>
                                                                    <TableCell>{member.last_name}</TableCell>
                                                                    <TableCell>{member.email}</TableCell>
                                                                    <TableCell>{Countries[member.country]}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                <Dialog open={dialogOpen} onClose={handleDialogClose} sx={{ '& .MuiDialog-paper': { width: '40%', maxWidth: 'none' } }}>
                    <DialogTitle>{selectedTeam ? 'Edit Team' : 'Add Team'}</DialogTitle>
                    <DialogContent dividers>

                        <Box display="flex" alignItems="center" mb={2}>
                            <BadgeIcon sx={{ marginRight: 1, color: 'action.active' }} />
                            <TextField
                                className='input primary'
                                autoFocus
                                margin="dense"
                                label="Team Name"
                                type="text"
                                fullWidth
                                value={teamName}
                                onChange={(e) => setTeamName(e.target.value)}
                            />
                        </Box>


                        <Box display="flex" alignItems="center" mb={2}>
                            <GroupIcon sx={{ marginRight: 1, color: 'action.active' }} />
                            <Autocomplete
                                className='input primary'
                                fullWidth
                                multiple
                                options={users}
                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                value={users.filter((user) => selectedMembers.includes(user._id))}
                                onChange={(event, newValue) => {
                                    setSelectedMembers(newValue.map((member) => member._id));
                                }}
                                renderInput={(params) => <TextField {...params} label="Assign Members" />}
                            />
                        </Box>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSaveTeam} className='button primary medium' startIcon={<SaveIcon />} disabled={isSaving}>
                            {selectedTeam ? 'Save Changes' : 'Add Team'}
                        </Button>
                        <Button onClick={handleDialogClose} className='button secondary medium' startIcon={<CancelIcon />}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Loader>
    );
};

export default TeamManagement;
