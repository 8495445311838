import axios from "axios";
import { coordinatesModel, FarmRecordModel } from "./shared/InputModels.js";
import * as turf from '@turf/turf';
import { jwtDecode } from "jwt-decode";


export function getUserData() {
    return JSON.parse(localStorage.getItem("user"))
}

export async function getFarmsData() {
    const response = await axios.get(process.env.REACT_APP_BACKEND_API + "/getUserFarms")


    return response.data.data
}


export async function setFarmsData(farms = [FarmRecordModel()]) {
    // remove frontend vars
    const savedFarms = farms.map(farm => {
        return { ...farm, layer: null, marker: null }
    })


    const response = await axios.post(process.env.REACT_APP_BACKEND_API + "/setUserFarms", { farms: savedFarms })
}



export function getisSideBarOpen() {
    const isOpen = (localStorage.getItem("isSideBarOpen"))



    return isOpen == "false" ? false : true
}




export function getPolygonLayerBounds(layer) {
    const latlngs = layer.getLatLngs()[0]; // Get LatLngs of the polygon
    const coordinates = latlngs.map(latlng => [latlng.lat, latlng.lng]);

    // Ensure the polygon is closed
    if (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
        coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
        coordinates.push(coordinates[0]); // Close the polygon
    }

    return coordinates
}


export function getPolygonTurfCenter(bounds) {
    const polygon = turf.polygon([bounds]);
    const center = turf.centroid(polygon).geometry.coordinates;


    return coordinatesModel(center[0], center[1])
}


export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
};




export function getFarmLastSimulationRecord(farm = FarmRecordModel()) {
    return farm.simulationRecords.length ? farm.simulationRecords[farm.simulationRecords.length - 1] : {}
}



export function isSimulationNameExist(simulation, farm) {
    let exist = false
    farm.simulationRecords.map(sim => {
        if (sim.name == simulation.name) {
            exist = true
            return
        }
    })

    return exist
}

export const removeCopyPattern = (str) => {
    return str.replace(/\(Copy \d+\)/g, '').trim(); // Remove the pattern and trim the result
};

export function getLastCopyNumber(simulationRecords) {
    let maxCopyNumber = 0;
    const copyPattern = /\(Copy (\d+)\)$/;

    simulationRecords.forEach(sim => {
        const match = sim.name.match(copyPattern);
        if (match) {
            const copyNumber = parseInt(match[1], 10);
            if (copyNumber > maxCopyNumber) {
                maxCopyNumber = copyNumber;
            }
        }
    });

    return maxCopyNumber;
}


export function addDaysToDate(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}



export const fetchWeatherData = async (lat, lon) => {
    try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_API + "/fetchWeatherData", {
            params: { lat, lon }
        });

        return response.data.data;
    } catch (error) {
        return null;
    }
};

export function getUserPosition(setAlert,callback) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                callback({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                })
            },
            (error) => {
                setAlert("Please enable location access for a better experience !",false)
                callback({
                    lat: 40,
                    lon: 40
                })
            }
        );
    } else {
        alert('Geolocation is not supported by this browser.');
        callback({
            lat: 40,
            lon: 40
        })
    }
}



export function isAuthenticated() {
    const token = localStorage.getItem("jwt")

    if (!token) return false

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000


        if (decoded.exp < currentTime) {
            localStorage.removeItem("jwt")
            return false
        }

        return true
    } catch (error) {
        return false
    }
}



export function getGMTOffset() {
    const offset = new Date().getTimezoneOffset();
    const sign = offset <= 0 ? "+" : "-";
    const hours = Math.abs(Math.floor(offset / 60));
    const minutes = Math.abs(offset % 60);

    return `GMT${sign}${hours}${minutes === 0 ? "" : ":" + minutes.toString().padStart(2, "0")}`;
}



export function getRandomRGBAColor() {
    const r = Math.floor(Math.random() * 256)
    const g = Math.floor(Math.random() * 256)
    const b = Math.floor(Math.random() * 256)
    const a = (Math.floor(Math.random() * 1) + 0.5).toFixed(1)

    return `rgba(${r}, ${g}, ${b}, ${a})`;
}


export function timestampToHour(timestamp = 1727956800, includeDay = false, includeHour = true) {
    const date = new Date(timestamp * 1000);

    const dayName = date.toLocaleString('en-US', { weekday: 'long' });
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';


    const formattedTime = `${hours % 12 || 12}:${minutes} ${period}`

    if (!includeDay) {
        return formattedTime
    }

    if (!includeHour) {
        return dayName
    }

    return `${dayName} - ${formattedTime}`
}