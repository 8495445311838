import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import { fetchWeatherData, getFarmLastSimulationRecord, getFarmsData, getUserPosition } from '../../Assets/Utils';
import { userStatisticsModel, WeatherForcastModel, weatherModel } from '../../Assets/shared/InputModels';
import { PiFarmFill } from "react-icons/pi";
import Loader from '../../Assets/Components/Loader';
import { Doughnut, PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale } from 'chart.js';
import { breedIcons, CardIconItem, cropIcons, getWeatherBackground } from '../../Assets/Components/General';
import { PiCowFill } from "react-icons/pi";
import axios from 'axios';
import { AlertContext } from '../../Assets/Components/Alerter';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    RadialLinearScale,
    //ChartDataLabels
);


const Dashboard = () => {
    const [farms, setFarms] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [userStatistics, setuserStatistics] = useState(userStatisticsModel())
    const { setAlert } = useContext(AlertContext)


    useEffect(() => {
        init()
    }, [])


    async function init() {
        getUserPosition(setAlert, async (userPos) => {

            try {
                const response = await axios.get(process.env.REACT_APP_BACKEND_API + "/getUserStatistics",)

                setuserStatistics(response.data.data)
            } catch (error) {
            }




            const farms = await getFarmsData()



            setFarms(farms)
            setisLoading(false)
        })
    }



    const breedDistribution = farms.reduce((acc, farm) => {
        if (!getFarmLastSimulationRecord(farm).herdProperties.herdSize) {
            return acc
        }
        const breed = getFarmLastSimulationRecord(farm).herdProperties.breed
        acc[breed] = (acc[breed] || 0) + getFarmLastSimulationRecord(farm).herdProperties.herdSize
        return acc
    }, {})




    const cropDistribution = farms.reduce((acc, farm) => {
        if (!getFarmLastSimulationRecord(farm).cropData.cropArea) {
            return acc
        }
        const crop = getFarmLastSimulationRecord(farm).cropData.cropType
        acc[crop] = (acc[crop] || 0) + 1
        return acc
    }, {})




    return (
        <Loader isLoading={isLoading}>
            <div className='container'>
                <Typography variant="h4" gutterBottom>
                    Overview
                </Typography>



                <div className='card-container shadow-low'>

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <CardIconItem label={"Total Farms"} value={userStatistics.countFarms}
                                icon={<PiFarmFill />}
                                backgroundColor={"#206a3a"} />
                        </Grid>


                        <Grid item xs={3}>
                            <CardIconItem label={"Total Livestock"} value={userStatistics.countLiveStock}
                                icon={<PiCowFill />}
                                backgroundColor={"#0081ff"} />
                        </Grid>

                        <Grid item xs={3}>
                            <CardIconItem label={"Total Team Participation"} value={userStatistics.countTeams}
                                icon={<GroupsIcon />}
                                backgroundColor={"#18548e"} />
                        </Grid>

                        <Grid item xs={3}>
                            <CardIconItem label={"Total Assigned Tasks"} value={userStatistics.countTasks}
                                icon={<AssignmentIcon />}
                                backgroundColor={"#844022"} />
                        </Grid>
                    </Grid>


                    {(Object.keys(cropDistribution).length || Object.keys(breedDistribution).length) ?
                        <div >
                            <Grid container spacing={2} mt={2} style={{ width: "80%", marginLeft: "auto", marginRight: "auto", justifyContent: "center" }}>
                                {Object.keys(cropDistribution).length ?
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className='card-container'>
                                            <div className='text primary xlarge'>Crop Distribution</div>
                                            <Grid item xs={12} sx={{ height: "50vh" }}>
                                                <CropChart cropDistribution={cropDistribution} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 16 }}>
                                                    {Object.keys(cropDistribution).map((crop) => (
                                                        <div key={crop}>
                                                            <div className='icon primary center large'>{cropIcons[crop]}</div>
                                                            <div className='text secondary center xlarge'>{crop}</div>
                                                            <div className='text primary center xlarge'>{cropDistribution[crop]}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    :
                                    ""
                                }

                                {Object.keys(breedDistribution).length ?
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className='card-container'>
                                            <div className='text primary xlarge'>Herd Breed Distribution</div>
                                            <Grid item xs={12} sx={{ height: "50vh" }}>
                                                <BreedChart breedDistribution={breedDistribution} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 16 }}>
                                                    {Object.keys(breedDistribution).map((breed) => (
                                                        <div key={breed}>
                                                            <div className='icon primary center large'>{breedIcons[breed]}</div>
                                                            <div className='text secondary center xlarge'>{breed}</div>
                                                            <div className='text primary center xlarge'>{breedDistribution[breed]}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    :
                                    ""
                                }
                            </Grid>

                        </div>
                        :
                        ""
                    }
                </div>




            </div >
        </Loader>
    );
};

export default Dashboard;



const CropChart = ({ cropDistribution }) => {
    const data = {
        labels: Object.keys(cropDistribution),
        datasets: [
            {
                label: 'Number of Farms',
                data: Object.values(cropDistribution),
                backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#AB47BC'],
                hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D', '#BA68C8'],
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        return `${label}: ${value}`;
                    },
                },
            },
            datalabels: {
                color: '#fff',
                anchor: 'center',
                align: 'center',
                font: {
                    weight: 'bold',
                    size: 16,
                },
                formatter: (value) => value,
            },
        },
    };

    return <Doughnut data={data} options={options} />;
};




const BreedChart = ({ breedDistribution }) => {
    const data = {
        labels: Object.keys(breedDistribution),
        datasets: [
            {
                label: 'Number of Cows',
                data: Object.values(breedDistribution),
                backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#AB47BC'],
                hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D', '#BA68C8'],
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        return `${label}: ${value}`;
                    },
                },
            },
        },
    };

    return <PolarArea data={data} options={options} />;
};
