
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate, useNavigation } from "react-router-dom"
import "./Assets/App.css"
import Home from "./Routes/Home"
import Header from "./Assets/Components/Header"
import Loader from "./Assets/Components/Loader"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AlerterComponent from "./Assets/Components/Alerter"
import Login from "./Routes/Login"
import UserDashboard from "./Assets/Components/UserDashboard"
import { EmptyPage, IconTitle } from "./Assets/Components/General"
import Signup from "./Routes/Singup"











export default function App() {



    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AlerterComponent>
                <BrowserRouter basename="/">
                    <Loader>
                        <Header />
                        <Routes>
                            <Route index path="/" Component={Home}></Route>
                            <Route path="/login" Component={Login}></Route>
                            <Route path="/signup" Component={Signup}></Route>
                            <Route path="/user/*" Component={UserDashboard}></Route>
                            <Route path="*" Component={EmptyPage}></Route>
                        </Routes>
                    </Loader>
                </BrowserRouter>
            </AlerterComponent>
        </LocalizationProvider>
    )
}