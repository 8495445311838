import { createContext, useEffect, useState } from "react";





export default function Loader({ isLoading,children }) {

    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [isLoading])


    if (isLoading) {
        return (
            <div className="loader">
                <img alt="" src={require("../img/loader.gif")}></img>
            </div>
        )
    } else {
        return (children)
    }
}