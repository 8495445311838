import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Container, TextField, Typography, Box, Alert, InputAdornment, Grid, Divider } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { AlertContext } from "../Assets/Components/Alerter";
import { isAuthenticated } from "../Assets/Utils";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

export default function Signup() {
    const navigate = useNavigate();


    const [email, setEmail] = useState("");
    const [username, setusername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");

    const [message, setMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const {setAlert}=useContext(AlertContext)


    const [errors, seterrors] = useState({
        email: false,
        username: false,
        password: false,
        confirmPassword: false
    })




    async function signup() {
        setisLoading(true)
        axios.post(process.env.REACT_APP_BACKEND_API + "/signup", { email, username, password, confirmPassword }
        ).then((response) => {
            setAlert("Account created successfully !")
            navigate("/login")
        }).catch((err) => {
            const msg = err.response.data.data
            setMessage(msg)

            if (msg.includes("email")) {
                seterrors({ email: true })
            }
            else if (msg.includes("username")) {
                seterrors({ username: true })
            }
            else if (msg.includes("password") && msg.includes("Check")) {
                seterrors({ password: true })
            }
            else if (msg.includes("password") && msg.includes("Confirm")) {
                seterrors({ confirmPassword: true })
            }
            else if (msg.includes("already") && msg.includes("Email")) {
                seterrors({ email: true })
            } else if (msg.includes("already") && msg.includes("Username")) {
                seterrors({ username: true })
            }
        })



        setisLoading(false)
    }




    if (isAuthenticated()) {
        return (
            <Navigate to={"/user"} replace />
        )
    } else
        return (
            <div className="container empty">
                <Grid className="card-container center" sx={{ width: "40% !important" }}>
                    <div onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            signup()
                        }
                    }}>

                        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                            <img
                                src={require("../Assets/img/logo.png")}
                                alt="logo"
                                style={{ width: 90 }}
                            />
                        </Box>

                        <div className="text bold secondary center xxlarge">Sign up</div>



                        <Box component="form" noValidate sx={{ mt: 3, mb: 3 }}>
                            <TextField
                                sx={{ mt: 2 }}
                                variant="outlined"
                                className="input primary"
                                fullWidth
                                label="Email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={errors.email}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                variant="outlined"
                                className="input primary"
                                fullWidth
                                label="Username"
                                value={username}
                                onChange={(e) => setusername(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DriveFileRenameOutlineIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={errors.username}
                            />

                            <Divider className="divider primary small" />

                            <TextField
                                variant="outlined"
                                className="input primary"
                                fullWidth
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={errors.password}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                variant="outlined"
                                className="input primary"
                                fullWidth
                                label="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setconfirmPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={errors.confirmPassword}
                            />

                            {message && message !== "ok" && (
                                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                                    {message}
                                </Alert>
                            )}
                        </Box>



                        <Button
                            disabled={isLoading}
                            fullWidth
                            variant="contained"
                            className="button primary"
                            onClick={signup}
                        >

                            {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                "Sign up"
                            )}

                        </Button>
                    </div>
                </Grid>
            </div>
        )
}