import axios from "axios"
import { getisSideBarOpen, getUserData, isAuthenticated } from "../Utils"
import Statistics from "../../Routes/User/Statistics"
import Simulation from "../../Routes/User/Farms"
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Dashboard from "../../Routes/User/Dashboard"
import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Toolbar, AppBar, Divider, Button, Tooltip, Menu, Avatar, MenuItem, Box } from '@mui/material';
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon, Close as CloseIcon } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { EmptyPage, IconText, Slogan } from "./General"
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Account from "../../Routes/User/Account"
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TeamManagement from "../../Routes/User/Teams"
import TaskManagement from "../../Routes/User/Tasks"
import { PiFarmFill } from "react-icons/pi";
import { BiSolidDoughnutChart } from "react-icons/bi";
import NotificationButton from "./Notifications"
import CloudIcon from '@mui/icons-material/Cloud';
import Weather from "../../Routes/User/Weather"
import { TiWeatherPartlySunny } from "react-icons/ti";


export default function UserDashboard() {
    const [isSideBarOpen, setisSideBarOpenState] = useState(getisSideBarOpen());
    const [anchorElUser, setAnchorElUser] = useState(null)
    const userData = getUserData()
    const location = useLocation()
    const navigate = useNavigate()



    function setisSideBarOpen(value) {
        setisSideBarOpenState(value)
        localStorage.setItem("isSideBarOpen", value)
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }


    if (isAuthenticated()) {
        // setup jwt middleware
        axios.interceptors.request.use((config) => {
            const token = localStorage.getItem("jwt")

            if (token) {
                config.headers["authorization"] = "Bearer " + token
            }
            return config
        }, (error) => {
            return Promise.reject(error)
        })

        return (
            <>
                <div className="header-container">
                    <div className="header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                sx={{
                                    mr: 2, '& .MuiSvgIcon-root': {
                                        color: "#fff"
                                    }
                                }}
                                onClick={() => {
                                    setisSideBarOpen(!isSideBarOpen);
                                }}
                            >
                                {isSideBarOpen ? <MenuOpenIcon /> : <MenuIcon />}
                            </IconButton>
                            <Slogan />
                        </div>


                        <ul className="nav">
                            <NotificationButton />
                            <Box>
                                <Tooltip title={userData.first_name + " " + userData.last_name}>
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt="Avatar" src={(userData.avatar ? userData.avatar : userData.first_name)} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={() => {
                                        handleCloseUserMenu()
                                        navigate("/")
                                    }}>
                                        <IconText icon={<HomeIcon style={{ marginRight: "10px" }} />} text={"Home"} />
                                    </MenuItem>



                                    <MenuItem onClick={() => {
                                        handleCloseUserMenu()
                                        localStorage.clear()
                                        navigate("/login")
                                    }}>
                                        <IconText icon={<LogoutIcon style={{ marginRight: "10px" }} />} text={"Logout"} />
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </ul>

                    </div>

                    <div className={isSideBarOpen ? "sidebar open" : "sidebar"}>
                        <div className='sidebar-header'>
                            <IconText icon={<SpaceDashboardIcon sx={{ mr: 2, color: "#fff" }} />} text={<div className="text primary2 large">Dashboard</div>} />

                            <IconButton onClick={() => setisSideBarOpen(false)} sx={{
                                '& .MuiSvgIcon-root': {
                                    color: "#fff"
                                }
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <Divider sx={{ mb: 3, bgcolor: "#fff" }} />


                        <div style={{ margin: "5%" }}>
                            <Button
                                fullWidth
                                variant="contained"
                                component={Link}
                                to="/user"
                                className={"button primary medium" + (location.pathname == "/user" ? " active" : "")}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<QueryStatsIcon sx={{ fontSize: "2rem !important" }} />}
                            >
                                Overview
                            </Button>

                            <Button
                                fullWidth
                                variant="contained"
                                component={Link}
                                to="/user/weather"
                                className={"button primary medium" + (location.pathname == "/user/weather" ? " active" : "")}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<TiWeatherPartlySunny style={{ fontSize: "2rem" }} />}
                            >
                                Weather
                            </Button>

                            <Button
                                fullWidth
                                variant="contained"
                                component={Link}
                                to="/user/teams"
                                className={"button primary medium" + (location.pathname == "/user/teams" ? " active" : "")}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<Diversity3Icon sx={{ fontSize: "2rem !important" }} />}
                            >
                                Teams
                            </Button>

                            <Button
                                fullWidth
                                variant="contained"
                                component={Link}
                                to="/user/tasks"
                                className={"button primary medium" + (location.pathname == "/user/tasks" ? " active" : "")}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<AssignmentIcon sx={{ fontSize: "2rem !important" }} />}
                            >
                                Tasks
                            </Button>

                            <Button
                                fullWidth
                                variant="contained"
                                component={Link}
                                to="/user/simulation"
                                className={"button primary medium" + (location.pathname.includes("/user/simulation") ? " active" : "")}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<PiFarmFill style={{ fontSize: "2rem" }} />}
                            >
                                Farms
                            </Button>

                            <Button
                                fullWidth
                                variant="contained"
                                component={Link}
                                to="/user/statistics"
                                className={"button primary medium" + (location.pathname == "/user/statistics" ? " active" : "")}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<BiSolidDoughnutChart style={{ fontSize: "2rem" }} />}
                            >
                                Reports
                            </Button>


                            <Divider sx={{ mb: 3, bgcolor: "#fff" }} />


                            <Button
                                fullWidth
                                variant="contained"
                                component={Link}
                                to="/user/profile"
                                className={"button primary medium" + (location.pathname == "/user/profile" ? " active" : "")}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<ManageAccountsIcon sx={{ fontSize: "2rem !important" }} />}
                            >
                                Account
                            </Button>

                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                className={"button medium"}
                                sx={{ mb: 2, p: 2 }}
                                startIcon={<PowerSettingsNewIcon sx={{ fontSize: "2rem !important" }} />}
                                onClick={() => {
                                    localStorage.clear()
                                    navigate("/login")
                                }}
                            >
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>


                <div className={isSideBarOpen ? "sidebar-body open" : "sidebar-body"}>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/statistics" element={<Statistics />} />
                        <Route path="/simulation" element={<Simulation />} />
                        <Route path="/simulation/:passedFarmId" element={<Simulation />} />
                        <Route path="/profile" element={<Account />} />
                        <Route path="/teams" element={<TeamManagement />} />
                        <Route path="/tasks" element={<TaskManagement />} />
                        <Route path="/weather" element={<Weather />} />

                        <Route path="*" Component={EmptyPage}></Route>
                    </Routes>
                </div>
            </>
        )
    } else {
        return (
            <Navigate to={"/login"} replace />
        )
    }
}