import React, { useState, useEffect } from 'react';
import {
    IconButton,
    Popover,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Badge,
    Box,
    Paper,
    Grid,
    Chip,
    Tooltip,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { getPriorityColor, IconTitle } from './General';
import { useLocation } from 'react-router-dom';

const NotificationButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const location = useLocation()


    useEffect(() => {
        const fetchNotifications = async () => {
            const response = await axios.get(process.env.REACT_APP_BACKEND_API + '/getUserNotifications')
            const unread = response.data.data.filter((n) => !n.viewed).length;

            setNotifications(response.data.data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
            setUnreadCount(unread)
        }

        fetchNotifications();
    }, [location.pathname])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const markAsRead = async (notificationId) => {
        const response = await axios.post(process.env.REACT_APP_BACKEND_API + "/viewNotification", { notificationId })

        setNotifications((prev) =>
            prev.map((notification) =>
                notification._id === notificationId
                    ? { ...notification, viewed: true }
                    : notification
            )
        );
        setUnreadCount(unreadCount - 1);
    };



    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;





    const renderNotificationContent = (notification) => {
        const { from, content } = notification


        switch (notification.code) {
            case 'assignTask':
                return (
                    <>
                        <AssignmentIcon color="primary" />

                        <Box ml={2}>
                            <div className="text primary bold">New Task Assigned</div>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Task:</div>
                                <div className='text secondary medium ml-small'>{content.task.title}</div>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Assigned by:</div>
                                <div className='text secondary medium ml-small'>{from.first_name} {from.last_name}</div>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Priority:</div>
                                <Box
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: '50%',
                                        backgroundColor: getPriorityColor(content.task.priority),
                                        ml: 1,
                                        mr: 1
                                    }}
                                    className="ml-small"
                                />
                                <div className='text primary bold small ' style={{ color: getPriorityColor(content.task.priority) }}>
                                    {content.task.priority}
                                </div>
                            </Box>
                        </Box>
                    </>
                );
            case 'unassignTask':
                return (
                    <>
                        <AssignmentIcon color="primary" />

                        <Box ml={2}>
                            <div className="text primary bold">Task Unassigned</div>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Task:</div>
                                <div className='text secondary medium ml-small'>{content.task.title}</div>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Unassigned by:</div>
                                <div className='text secondary medium ml-small'>{from.first_name} {from.last_name}</div>
                            </Box>
                        </Box>
                    </>
                );
            case 'assignTeam':
                return (
                    <>
                        <AssignmentIcon color="primary" />

                        <Box ml={2}>
                            <div className="text primary bold">New Team Assigned</div>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Team:</div>
                                <div className='text secondary medium ml-small'>{content.team.name}</div>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Assigned by:</div>
                                <div className='text secondary medium ml-small'>{from.first_name} {from.last_name}</div>
                            </Box>
                        </Box>
                    </>
                );
            case 'unassignTeam':
                return (
                    <>
                        <AssignmentIcon color="primary" />

                        <Box ml={2}>
                            <div className="text primary bold">Team Unassigned</div>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Team:</div>
                                <div className='text secondary medium ml-small'>{content.team.name}</div>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Unassigned by:</div>
                                <div className='text secondary medium ml-small'>{from.first_name} {from.last_name}</div>
                            </Box>
                        </Box>
                    </>
                );
            case 'leaveTeam':
                return (
                    <>
                        <AssignmentIcon color="primary" />

                        <Box ml={2}>
                            <div className="text primary bold">Someone Left your Team</div>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Team:</div>
                                <div className='text secondary medium ml-small'>{content.team.name}</div>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="mt-small">
                                <div className='text primary bold medium'>Member:</div>
                                <div className='text secondary medium ml-small'>{from.first_name} {from.last_name}</div>
                            </Box>
                        </Box>
                    </>
                );

            default:
                return null;
        }
    };



    return (
        <div className='button primary mr-small'>
            <IconButton
                aria-describedby={id}
                onClick={handleClick}
                color="inherit"
            >
                <Badge badgeContent={unreadCount} color="error">
                    <div className='icon primary2 large'><NotificationsIcon /></div>
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box padding={2}>
                    <div className='text primary bold large'>Notifications</div>

                    {notifications.length ? notifications.map((notification) => (
                        <div className='card-container mt-medium' style={{ display: "flex", justifyContent: "left", backgroundColor: notification.viewed ? "#ddd" : "inherit" }} key={notification._id}>
                            <div className='ml-small' style={{ display: "flex", alignItems: "center" }}>
                                {renderNotificationContent(notification)}
                            </div>
                            <div className='text secondary small' style={{ position: "relative", top: 0, right: 0, margin: 2 }}>
                                {new Date(notification.createdDate).toLocaleString()}
                            </div>
                            {!notification.viewed && (
                                <>
                                    <Chip label="NEW" className='button primary small' style={{ position: "absolute", top: 0, left: 0, cursor: "default" }} />

                                    <Tooltip title="Mark as Read">
                                        <IconButton
                                            onClick={() => markAsRead(notification._id)}
                                            className='button primary small'
                                            style={{ position: "absolute", bottom: 0, right: 0, margin: 5 }}
                                        >
                                            <CheckCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </div>
                    ))
                        :
                        <IconTitle style={{ height: "100%" }} imgStyle={{ width: "30vh" }} titleStyle={{ fontSize: "1.5rem" }} title={""} icon={require("../img/404.gif")} />
                    }
                </Box>
            </Popover>
        </div>
    );
};

export default NotificationButton;
