import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Select,
    MenuItem,
    Autocomplete,
    Paper,
    Grid,
    CircularProgress,
    Snackbar,
    Box,
    Avatar,
    Tooltip,
    IconButton,
    Chip,
    Divider,
    FormControl,
    InputLabel
} from '@mui/material'
import { Add, Edit, Delete } from '@mui/icons-material'
import axios from 'axios'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getUserData } from '../../Assets/Utils'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Close, PriorityHigh, CalendarToday, PeopleAlt, Description, AssignmentTurnedIn } from '@mui/icons-material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import GroupIcon from '@mui/icons-material/Group'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import { AlertContext } from '../../Assets/Components/Alerter'
import Loader from '../../Assets/Components/Loader'
import { getPriorityColor, getStatusColor, getStatusIcon, IconTitle } from '../../Assets/Components/General'

const TaskManagement = () => {
    const [teams, setTeams] = useState([])
    const [filteredTeams, setfilteredTeams] = useState([])
    const [selectedTeamCategory, setSelectedTeamCategory] = useState(0)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [tasks, setTasks] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [viewDialogOpen, setviewDialogOpen] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [taskMembers, setTaskMembers] = useState([])
    const [status, setStatus] = useState('Pending')
    const [priority, setPriority] = useState('Low')
    const [dueDate, setDueDate] = useState('')
    const [teamMembers, setTeamMembers] = useState([])
    const userData = getUserData()
    const { setAlert } = useContext(AlertContext)
    const [isLoading, setisLoading] = useState(true)
    const [isSaving, setisSaving] = useState(false)
    const [isDeleting, setisDeleting] = useState(false)



    useEffect(() => {
        if (!teams.length) {
            return
        }

        let newFilteredTeams = []
        if (selectedTeamCategory == 0) {
            newFilteredTeams = teams
        } else if (selectedTeamCategory == 1) {
            newFilteredTeams = teams.filter(team => team.owner._id == userData._id)
        } else {
            newFilteredTeams = teams.filter(team => team.owner._id != userData._id)
        }

        setfilteredTeams(newFilteredTeams)
        setSelectedTeam(newFilteredTeams[0]?._id)
    }, [teams, selectedTeamCategory])

    useEffect(() => {
        fetchTeams()
    }, [])

    useEffect(() => {
        fetchTasks()
        fetchTeamMembers()
    }, [selectedTeam])




    const fetchTeams = async () => {
        setisLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/getUserTeams`)
            setTeams(response.data.data)
            if (response.data.data.length > 0) {
                setSelectedTeam(response.data.data[0]._id)
            }
        } catch (error) {
            setAlert(error, false)
        } finally {
            setisLoading(false)
        }
    }


    const fetchTasks = async () => {
        if (selectedTeam) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/getTeamTasks`, { teamId: selectedTeam })
                setTasks(response.data.data)
            } catch (error) {
                setAlert(error, false)
            }
        }
    }


    const fetchTeamMembers = async () => {
        if (selectedTeam) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/getTeamMembers`, { teamId: selectedTeam })
                setTeamMembers(response.data.data)
            } catch (error) {
                setAlert(error, false)
            }
        }
    }



    const handleDialogOpen = (task, view = false) => {
        if (task) {
            setSelectedTask(task)
            setTitle(task.title || '')
            setDescription(task.description || '')
            setTaskMembers(task.members.map(member => member._id) || [])
            setStatus(task.status || 'Pending')
            setPriority(task.priority || 'Low')
            setDueDate(task.dueDate ? new Date(task.dueDate).toISOString().slice(0, 10) : '')
        } else {
            setSelectedTask(null)
            setTitle('')
            setDescription('')
            setTaskMembers([])
            setStatus('Pending')
            setPriority('Low')
            setDueDate('')
        }
        if (view) {
            setviewDialogOpen(true)
        } else {
            setDialogOpen(true)
        }

    }

    const handleDialogClose = () => {
        setDialogOpen(false)
        setviewDialogOpen(false)
    }


    const handleDeleteTask = async (taskId) => {
        setisDeleting(true)
        axios.post(`${process.env.REACT_APP_BACKEND_API}/removeTeamTask`, { taskId }).then(() => {

        }).catch((err) => {
            setAlert(err, false)
        }).finally(() => {
            fetchTasks().finally(() => {
                setisDeleting(false)
            })
        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setisSaving(true)


        try {
            const taskData = {
                title,
                description,
                members: taskMembers,
                status,
                priority,
                dueDate,
            }
            if (selectedTask) {
                await axios.post(`${process.env.REACT_APP_BACKEND_API}/editTeamTask`, { ...taskData, taskId: selectedTask._id })
            } else {
                await axios.post(`${process.env.REACT_APP_BACKEND_API}/addTeamTask`, { ...taskData, teamId: selectedTeam })
            }
            fetchTasks().then(() => {
                handleDialogClose()
            })

        } catch (error) {
            setAlert(error)
        } finally {
            setisSaving(false)
        }
    }

    const handleDragEnd = async (result) => {
        if (!result.destination) return

        const movedTask = tasks.find(task => task._id == result.draggableId)
        const previousStatus = movedTask.status
        movedTask.status = result.destination.droppableId
        setTasks(tasks.map(task => task._id == movedTask._id ? movedTask : task))

        try {
            await axios.post(process.env.REACT_APP_BACKEND_API + "/updateTaskStatus", { taskId: movedTask._id, status: movedTask.status })
        } catch (err) {
            movedTask.status = previousStatus
            setTasks(tasks.map(task => task._id == movedTask._id ? movedTask : task))
            setAlert(err.response.data.data, false)
        }
    }


    const groupedTasks = {
        Pending: tasks.filter((task) => task.status === 'Pending'),
        'In Progress': tasks.filter((task) => task.status === 'In Progress'),
        Completed: tasks.filter((task) => task.status === 'Completed'),
    }




    const getMemberNames = () => {
        return teamMembers
            .filter(member => selectedTask?.members.map(member => member._id).includes(member._id))
            .map(member => `${member.first_name} ${member.last_name}`)
            .join(', ')
    }






    return (
        <Loader isLoading={isLoading}>
            {!teams.length ?
                <IconTitle style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }} title={"You currently have no teams to manage tasks. Create or join a team to get started!"} icon={require("../../Assets/img/task management.gif")} />
                :
                <div className='container'>
                    <div className='text primary xxlarge'>Task Management</div>

                    <div className='card-container'>
                        <div className='text primary large'>Select Team Category</div>
                        <Select
                            value={selectedTeamCategory}
                            onChange={(e) => setSelectedTeamCategory(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value={0}>
                                All Teams
                            </MenuItem>
                            <MenuItem value={1}>
                                Your Teams
                            </MenuItem>
                            <MenuItem value={2}>
                                Joined Teams
                            </MenuItem>
                        </Select>

                        <Divider className='divider primary medium' />

                        <div className='text primary large'>Select Team</div>
                        <Select
                            value={selectedTeam}
                            onChange={(e) => setSelectedTeam(e.target.value)}
                            displayEmpty
                            fullWidth
                            disabled={!filteredTeams.length}
                        >
                            {filteredTeams.map((team) => (
                                <MenuItem key={team._id} value={team._id}>
                                    {team.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <Divider className='divider primary medium' />

                        <Button
                            variant="contained"
                            className='button primary medium'
                            onClick={() => handleDialogOpen(null)}
                            disabled={!selectedTeam || teams.find(team => team._id === selectedTeam).owner._id !== userData._id}
                        >
                            <Add /> Add Task
                        </Button>
                    </div>



                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Grid container spacing={3} style={{ marginTop: '20px' }}>
                            {['Pending', 'In Progress', 'Completed'].map((status) => (
                                <Grid item xs={4} key={status}>
                                    <Paper elevation={3} style={{ padding: '10px', minHeight: '400px' }}>
                                        <div className='text primary bold xlarge'>{status}</div>
                                        <Divider className='divider secondary medium' />

                                        <Droppable droppableId={status}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={{ backgroundColor: snapshot.isDraggingOver ? '#e0e0e0' : '#fff', minHeight: '380px' }}
                                                >
                                                    {groupedTasks[status].map((task, index) => (
                                                        <Draggable key={task._id} draggableId={task._id} index={index}>
                                                            {(provided) => (
                                                                <Paper
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    sx={{
                                                                        padding: '10px',
                                                                        marginBottom: '10px',
                                                                        display: 'flex',
                                                                        alignItems: 'flex-start',
                                                                        backgroundColor: '#f9f9f9',
                                                                        border: '1px solid #e0e0e0',
                                                                        borderRadius: '8px',
                                                                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                                                        ...provided.draggableProps.style
                                                                    }}
                                                                >
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                                                        {task.members.map(member => (
                                                                            <Tooltip title={member.first_name + " " + member.last_name}>
                                                                                <Avatar
                                                                                    key={member._id}
                                                                                    alt={member.first_name}
                                                                                    src={member.avatar ? member.avatar : member.first_name}
                                                                                    sx={{ marginRight: '5px', }}
                                                                                />
                                                                            </Tooltip>
                                                                        ))}
                                                                    </Box>

                                                                    <div style={{ flex: 1 }}>
                                                                        <div
                                                                            className='text primary bold large'
                                                                            style={{
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                display: '-webkit-box',
                                                                                WebkitLineClamp: 2,
                                                                                WebkitBoxOrient: 'vertical',
                                                                                overflowWrap: "anywhere",
                                                                                position: 'relative'
                                                                            }}
                                                                        >
                                                                            {task.title}
                                                                            {!task.members.length && (
                                                                                <span style={{
                                                                                    color: 'white',
                                                                                    backgroundColor: '#ff0000',
                                                                                    borderRadius: '5px',
                                                                                    padding: '2px 5px',
                                                                                    marginLeft: '10px',
                                                                                    fontSize: '0.9em',
                                                                                    position: 'absolute',
                                                                                    top: '0',
                                                                                    right: '0'
                                                                                }}>
                                                                                    Unassigned
                                                                                </span>
                                                                            )}
                                                                        </div>



                                                                        <div className='text primary small'>
                                                                            Due: {new Date(task.dueDate).toLocaleDateString()}
                                                                        </div>




                                                                        <Divider className='divider secondary small' />

                                                                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                                                            <div className='text primary bold medium'>Priority:</div>
                                                                            <Box
                                                                                sx={{
                                                                                    width: 10,
                                                                                    height: 10,
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: getPriorityColor(task.priority),
                                                                                    ml: 1,
                                                                                    mr: 1
                                                                                }}
                                                                            />
                                                                            <div className='text primary bold small' style={{ color: getPriorityColor(task.priority) }}>
                                                                                {task.priority}
                                                                            </div>
                                                                        </Box>

                                                                        <div
                                                                            className='text secondary medium mt-small'
                                                                            style={{
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                display: '-webkit-box',
                                                                                WebkitLineClamp: 2,
                                                                                WebkitBoxOrient: 'vertical',
                                                                                overflowWrap: "anywhere"
                                                                            }}
                                                                        >
                                                                            {task.description}
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <Tooltip title="View" sx={{ ml: 1 }}>
                                                                            <IconButton className='button primary' onClick={() => handleDialogOpen(task, true)}>
                                                                                <VisibilityIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {task.owner._id === userData._id && (
                                                                            <>
                                                                                <Tooltip title="Edit" sx={{ ml: 1 }}>
                                                                                    <IconButton className='button secondary' onClick={() => handleDialogOpen(task)}>
                                                                                        <Edit />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete" sx={{ ml: 1 }}>
                                                                                    <IconButton className='button error' disabled={isDeleting} onClick={() => handleDeleteTask(task._id)}>
                                                                                        <Delete />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </Paper>

                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </DragDropContext>




                    <Dialog open={dialogOpen} onClose={handleDialogClose} sx={{ '& .MuiDialog-paper': { width: '40%', maxWidth: 'none' } }}>
                        <DialogTitle>
                            {selectedTask ? 'Edit Task' : 'Add Task'}
                            <IconButton
                                aria-label="close"
                                onClick={handleDialogClose}
                                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                            >
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box display="flex" alignItems="center" mb={2}>
                                <AssignmentIcon sx={{ marginRight: 1, color: 'action.active' }} />
                                <TextField
                                    className='input primary'
                                    autoFocus
                                    margin="dense"
                                    label="Title"
                                    type="text"
                                    fullWidth
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Box>

                            <Box display="flex" alignItems="flex-start" mb={2}>
                                <Description sx={{ marginRight: 1, color: 'action.active' }} />
                                <TextField
                                    className='input primary'
                                    margin="dense"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    variant="outlined"
                                />
                            </Box>


                            <Box display="flex" alignItems="center" mb={2}>
                                <GroupIcon sx={{ marginRight: 1, color: 'action.active' }} />
                                <Autocomplete
                                    className='input primary'
                                    fullWidth
                                    multiple
                                    options={teamMembers}
                                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                    value={taskMembers.map(id => teamMembers.find(member => member._id === id))}
                                    onChange={(event, newValue) => {
                                        setTaskMembers(newValue.map((member) => member._id))
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Assign Members" />}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" mb={2}>
                                <CalendarToday sx={{ marginRight: 1, color: 'action.active' }} />
                                <div className='mr-small'>
                                    <InputLabel>Due Date</InputLabel>
                                </div>
                                <TextField
                                    fullWidth
                                    className='input primary'
                                    margin="dense"
                                    type="date"
                                    value={dueDate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                />

                            </Box>


                            <Box display="flex" alignItems="center" mb={2}>
                                <PriorityHigh sx={{ marginRight: 1, color: 'action.active' }} />
                                <FormControl fullWidth>
                                    <InputLabel>Priority</InputLabel>
                                    <Select
                                        className='input primary'
                                        value={priority}
                                        onChange={(e) => setPriority(e.target.value)}
                                        label="Priority"
                                    >
                                        {['Low', 'Medium', 'High'].map((priority) => (
                                            <MenuItem key={priority} value={priority}>
                                                {priority}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>


                            <Box display="flex" alignItems="center" mb={2}>
                                <AssignmentTurnedIn sx={{ marginRight: 1, color: 'action.active' }} />
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        className='input primary'
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        label="Status"
                                    >
                                        {['Pending', 'In Progress', 'Completed'].map((status) => (
                                            <MenuItem key={status} value={status}>
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSubmit} className='button primary medium' startIcon={<SaveIcon />} disabled={isSaving}>
                                {selectedTask ? 'Save Changes' : 'Add Task'}
                            </Button>
                            <Button onClick={handleDialogClose} className='button secondary medium' startIcon={<CancelIcon />}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>





                    <Dialog open={viewDialogOpen} onClose={handleDialogClose} sx={{ '& .MuiDialog-paper': { width: '40%', maxWidth: 'none' } }}>
                        <DialogTitle>
                            {selectedTask?.title}
                            <IconButton
                                aria-label="close"
                                onClick={handleDialogClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                                <Avatar alt="Task Avatar" sx={{ width: 80, height: 80, marginBottom: 2, backgroundColor: getStatusColor(selectedTask?.status) }}>
                                    {getStatusIcon(selectedTask?.status)}
                                </Avatar>
                                <Chip
                                    label={selectedTask?.status}
                                    sx={{ backgroundColor: getStatusColor(selectedTask?.status), color: 'white', fontWeight: 'bold' }}
                                />
                            </Box>



                            {/* Task Description */}
                            <Box display="flex" alignItems="center" mb={2}>
                                <Description sx={{ marginRight: 1 }} />
                                <div className='text primary large'>{selectedTask?.description}</div>
                            </Box>

                            {/* Due Date */}
                            <Box display="flex" alignItems="center" mb={2}>
                                <CalendarToday sx={{ marginRight: 1 }} />
                                <div className='text primary large'>Due Date: {new Date(selectedTask?.dueDate).toLocaleDateString()}</div>
                            </Box>

                            {/* Task Priority */}
                            <Box display="flex" alignItems="center" mb={2}>
                                <PriorityHigh sx={{ marginRight: 1 }} />
                                <div className='text primary large mr-small'>Priority: </div>
                                <Chip
                                    label={selectedTask?.priority}
                                    sx={{ backgroundColor: getPriorityColor(selectedTask?.priority), color: 'white', fontWeight: 'bold' }}
                                />
                            </Box>

                            {/* Assigned Members */}
                            <Box display="flex" alignItems="center" mb={2}>
                                <PeopleAlt sx={{ marginRight: 1 }} />
                                <div className='text primary large' style={{ position: 'relative' }}>
                                    Assigned Members: {getMemberNames()}
                                    {!getMemberNames() && (
                                        <span style={{
                                            color: 'white',
                                            backgroundColor: '#ff0000',
                                            borderRadius: '5px',
                                            padding: '2px 5px',
                                            marginLeft: '10px',
                                            fontSize: '0.9em',
                                        }}>
                                            Unassigned
                                        </span>
                                    )}
                                </div>
                            </Box>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} className='button primary medium' variant="contained">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            }
        </Loader>
    )
}

export default TaskManagement
