import { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Countries } from "../Assets/countries";
import { breeds, cropTypes, statisticsModel } from "../Assets/shared/InputModels";
import { getRandomRGBAColor } from "../Assets/Utils";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { PiFarmFill } from "react-icons/pi";
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import { Grid, Typography, Button, Divider } from "@mui/material";
import { breedIcons, CardIconItem, cropIcons, ImageCard } from "../Assets/Components/General";
import axios from "axios";
import Loader from "../Assets/Components/Loader";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GrassIcon from '@mui/icons-material/Grass';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import PetsIcon from '@mui/icons-material/Pets';
import NatureIcon from '@mui/icons-material/Nature';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CloudIcon from '@mui/icons-material/Cloud';
import WarningIcon from '@mui/icons-material/Warning';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import GroupsIcon from '@mui/icons-material/Groups';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


export default function Home() {
    const [isLoading, setisLoading] = useState(true)
    const [statistics, setstatistics] = useState(statisticsModel())
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_API + "/getStatistics").then((response) => {
            setstatistics(response.data.data)
        }).catch(() => {

        }).finally(() => {
            setisLoading(false)
        })
    }, [])


    const FeaturesCardStyle = {
        backgroundColor: "#fff",
        cursor: "default",
        transition: "0.5s",
        "&:hover": {
            backgroundColor: "#00ab5c3b",
            transform: "scale(1.1)",
            transition: "0.2s"
        }
    }

    const statisticsCardStyle = {
        backgroundColor: "var(--mui-palette-neutral-700)",
        cursor: "default",
    }


    return (
        <Loader isLoading={isLoading}>
            <div className="container mt-large" style={{
                padding: "2% 0%"
            }}>



                <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={3000}>
                    <div style={{ position: 'relative' }}>
                        <img src={require("../Assets/img/farm.jpg")} alt="Wallpaper 1" />
                        <div style={carouselLabelStyles}>
                            <Typography variant="h2" color="white">Optimize Farm Management</Typography>
                            <Typography variant="h6" color="white">Streamline your daily tasks with smart tools.</Typography>
                        </div>
                    </div>


                    <div style={{ position: 'relative' }}>
                        <img src={require("../Assets/img/forage.jpg")} alt="Wallpaper 2" />
                        <div style={carouselLabelStyles}>
                            <Typography variant="h2" color="white">Boost Forage Productivity</Typography>
                            <Typography variant="h6" color="white">Optimize forage growth with data-driven insights.</Typography>
                        </div>
                    </div>


                    <div style={{ position: 'relative' }}>
                        <img src={require("../Assets/img/crop.jpg")} alt="Wallpaper 3" />
                        <div style={carouselLabelStyles}>
                            <Typography variant="h2" color="white">Manage Crop</Typography>
                            <Typography variant="h6" color="white">Leverage real-time data to maximize your crop yield.</Typography>
                        </div>
                    </div>

                    <div style={{ position: 'relative' }}>
                        <img src={require("../Assets/img/herd.jpg")} alt="Wallpaper 4" />
                        <div style={carouselLabelStyles}>
                            <Typography variant="h2" color="white">Ensure Herd Well-being</Typography>
                            <Typography variant="h6" color="white">Keep your livestock healthy with advanced monitoring tools.</Typography>
                        </div>
                    </div>

                    <div style={{ position: 'relative' }}>
                        <img src={require("../Assets/img/meteo.jpg")} alt="Wallpaper 5" />
                        <div style={carouselLabelStyles}>
                            <Typography variant="h2" color="white">Accurate Weather Forecasts</Typography>
                            <Typography variant="h6" color="white">Plan your farming activities with precise meteorological data.</Typography>
                        </div>
                    </div>
                </Carousel>



                <div style={{ padding: "5vh", backgroundColor: "var(--mui-palette-neutral-700)" }}>
                    <div className="text primary2 center" style={{ fontSize: "3rem" }}>Focus on Your Passion, Not the Paperwork.</div>

                    <Button
                        variant="contained"
                        className="button primary2 success large"
                        sx={{
                            padding: 2,
                            borderRadius: "30px !important",
                            display: "flex",
                            mr: "auto",
                            ml: "auto",
                            mt: 2,
                            mb: 2
                        }}
                        onClick={() => {
                            navigate("/signup")
                        }}
                    >
                        Get Started
                    </Button>


                    <div className="text primary2 large center">✓ No Credit Card Required    ✓ Full Access to All Features</div>
                </div>



                <div className="card-container">
                    <div className='text primary xxlarge center mb-medium'>Know more. Grow more. Sell more.</div>


                    <Grid container spacing={4} sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={3}>
                            <CardIconItem label={"Optimize Farm Efficiency"}
                                value={"Collaborate with your team to streamline farm operations. Track everything in real-time, from field activities to equipment usage, improving overall farm efficiency."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<AssignmentIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={3}>
                            <CardIconItem label={"Increase Crop Yields"}
                                value={"Leverage data insights and weather forecasts to optimize planting, irrigation, and harvesting schedules, resulting in higher and more reliable crop yields."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<GrassIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={3}>
                            <CardIconItem label={"Boost Soil Health"}
                                value={"Use smart recommendations to maintain fertile soil and enhance crop growth."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<NatureIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                    </Grid>


                    <Grid container spacing={4} mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={3}>
                            <CardIconItem label={"Maximize Resource Efficiency"}
                                value={"Optimize water usage, energy consumption, and labor allocation using predictive models. Reduce waste and improve resource allocation across all farm activities."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<LocalGasStationIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>

                        <Grid item xs={3}>
                            <CardIconItem label={"Adapt to Changing Conditions"}
                                value={"Stay ahead of environmental changes with precise weather and climate data. Adapt farm operations in real-time to changing conditions and minimize risks."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<CloudIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={3}>
                            <CardIconItem label={"User-Friendly and Easy to Implement"}
                                value={"No complex setup or technical skills required. The system is designed to be intuitive and easy to use."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<ThumbsUpDownIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={4}>
                            <CardIconItem label={"Turn Data into Action"}
                                value={"Leverage comprehensive farm data to make informed decisions. Analyze trends, optimize resource use, and ensure compliance with regulatory requirements."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<DataUsageIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={4}>
                            <CardIconItem label={"Simplify Record-Keeping"}
                                value={"Eliminate the need for spreadsheets by managing farm records in one centralized system. Access all data securely from any device for convenience and efficiency."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<DescriptionIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                    </Grid>
                </div>


                <div style={{ padding: "5vh", backgroundColor: "var(--mui-palette-neutral-700)" }}>
                    <div className='text primary2 xxlarge center'>Our Growth Story</div>

                    <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" ,alignItems:"center"}}>
                        <Grid item xs={2}>
                            <CardIconItem label={"Total Managed Farms"} value={statistics.countFarms}
                                icon={<PiFarmFill />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>


                        <Grid item xs={2}>
                            <CardIconItem label={"Total Users"} value={statistics.countUsers}
                                icon={<SupervisedUserCircleIcon />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>



                        {Object.keys(statistics.countNationalities).find(key => statistics.countNationalities[key]) ?
                            <Grid item xs={3}>
                                <CardIconItem label={"Nationalities"} value={Object.keys(statistics.countNationalities).length}
                                    icon={<Grid item xs={12} sx={{ height: "25vh" }}>
                                        <NationalityChart data={statistics} />
                                    </Grid>}
                                    cardStyle={statisticsCardStyle} />
                            </Grid>
                            :
                            ""
                        }

                        <Grid item xs={2}>
                            <CardIconItem label={"Total Teams"} value={statistics.countTeams}
                                icon={<GroupsIcon />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>


                        <Grid item xs={2}>
                            <CardIconItem label={"Total Simulations"} value={statistics.countUsers}
                                icon={<VideoSettingsIcon />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>


                    </Grid>
                </div>






                <div className='card-container'>
                    <div className='text primary xxlarge center'>Supported Crops</div>

                    <Grid item xs={12}>
                        <div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {cropTypes.map((crop) => (
                                <ImageCard
                                    key={crop}
                                    name={crop}
                                    image={require(`../Assets/img/crops/${crop.replace(/ /g, '_')}.jpg`)}
                                />
                            ))}
                        </div>
                    </Grid>
                </div>




                <div className='card-container'>
                    <div className='text primary xxlarge center'>Supported Herd Breeds</div>

                    <Grid item xs={12}>
                        <div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {breeds.map((breed) => (
                                <ImageCard
                                    key={breed}
                                    name={breed}
                                    image={require(`../Assets/img/herd/${breed.replace(/ /g, '_')}.jpg`)}
                                />
                            ))}
                        </div>
                    </Grid>
                </div>





            </div>
        </Loader>
    );
}







const carouselLabelStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
};

const NationalityChart = ({ data }) => {
    const nationalityData = {
        labels: Object.keys(data.countNationalities).map((CountryCode => Countries[CountryCode])),
        datasets: [
            {
                data: Object.values(data.countNationalities),
                backgroundColor: Object.keys(data.countNationalities).map(a => getRandomRGBAColor())
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: false,
                text: 'Nationality Distribution',
            },
        },
    }

    return <Pie data={nationalityData} options={options} />
}