import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Container, TextField, Typography, Box, Alert, InputAdornment, Grid } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { AlertContext } from "../Assets/Components/Alerter";
import { isAuthenticated } from "../Assets/Utils";


export default function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [message, setMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);

    
    const [errors, seterrors] = useState({
        email: false,
        pass: false
    })




    async function login() {
        setisLoading(true)
        axios.post(process.env.REACT_APP_BACKEND_API + "/login",
            email.includes("@") ? { email, password } : { username: email, password }
        ).then((response) => {
            localStorage.setItem("jwt", response.data.data.token)
            localStorage.setItem("user", JSON.stringify(response.data.data.user))
            navigate("/user")
        }).catch((err) => {
            const msg = err.response.data.data
            setMessage(msg)

            if (msg.includes("found")) {
                seterrors({ email: true })
            } else if (msg.includes("credentials")) {
                seterrors({ pass: true })
            }
        })



        setisLoading(false)
    }

    if (isAuthenticated()) {
        return (
            <Navigate to={"/user"} replace />
        )
    } else
        return (
            <div className="container empty">
                <Grid className="card-container center" sx={{ width: "40% !important" }}>
                    <div onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            login()
                        }
                    }}>

                        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                            <img
                                src={require("../Assets/img/logo.png")}
                                alt="logo"
                                style={{ width: 90 }}
                            />
                        </Box>

                        <div className="text bold secondary center xxlarge">Login</div>



                        <Box component="form" noValidate sx={{ mt: 3, mb: 3 }}>
                            <TextField
                                variant="outlined"
                                className="input primary"
                                fullWidth
                                label="Email / Username"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={errors.email}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                variant="outlined"
                                className="input primary"
                                fullWidth
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={errors.pass}
                            />

                            {message && message !== "ok" && (
                                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                                    {message}
                                </Alert>
                            )}
                        </Box>



                        <Button
                            disabled={isLoading}
                            fullWidth
                            variant="contained"
                            className="button primary"
                            onClick={login}
                        >

                            {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                "Login"
                            )}

                        </Button>


                        <Button
                            sx={{ mt: 1 }}
                            fullWidth
                            variant="outlined"
                            className="button secondary"
                            onClick={() => navigate("/signup")}
                        >
                            Signup
                        </Button>
                    </div>
                </Grid>
            </div>
        )
}