import { Avatar, Box, Card, CardContent, IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { LuWheat } from "react-icons/lu"
import { GiWheat } from "react-icons/gi"
import { GiPeas } from "react-icons/gi"
import { FaCow, FaPlateWheat } from "react-icons/fa6"
import { GiCow } from 'react-icons/gi'
import { PiCowFill, PiCowLight } from "react-icons/pi"
import { WiRainMix, WiRain, WiThunderstorm, WiDaySunny, WiCloudy, WiSnow, WiFog, WiCloud } from 'react-icons/wi'
import { BsCloudSun } from 'react-icons/bs'
import PendingIcon from '@mui/icons-material/Pending';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TaskIcon from '@mui/icons-material/Task';
import { useState } from "react"

export const cropIcons = {
    "Wheat": <LuWheat style={{ color: '#F4A261' }} />,
    "Barley": <GiWheat style={{ color: '#1adfc7' }} />,
    'Field peas': <GiPeas style={{ color: '#264653' }} />,
    "Lupins": <FaPlateWheat style={{ color: '#E76F51' }} />
}

export const breedIcons = {
    "Holstein": <GiCow style={{ color: '#6bd4ff' }} />,
    "Jersey": <FaCow style={{ color: '#2A9D8F' }} />,
    "Guernsey": <PiCowFill style={{ color: '#E9C46A' }} />,
    "Ayrshire": <PiCowLight style={{ color: '#F4A261' }} />
}



export function IconText({ icon, text }) {
    return (
        <Box display="flex" alignItems="center" className="text primary center large">
            {icon}
            {text}
        </Box>
    )
}





export function CardIconItem({ label, labelStyle = {}, valueStyle = {}, value, icon, backgroundColor = "#bbb", cardStyle }) {
    return (
        <Card
            variant="outlined"
            sx={{
                borderRadius: 2,
                boxShadow: `0px 0px 5px 1px ${cardStyle?.backgroundColor ? cardStyle.backgroundColor : backgroundColor}`,
                backgroundColor: backgroundColor,
                ...cardStyle
            }}
        >
            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div className="icon primary2 xlarge">{icon}</div>
                <div className="text center primary2 xlarge" style={labelStyle}>
                    {label}
                </div>
                <div className="text center primary2 xlarge" style={{ fontSize: 32, fontWeight: "bold", marginTop: 10, ...valueStyle }}>
                    {value}
                </div>
            </CardContent>
        </Card>
    )
}





export function Slogan() {
    const navigate = useNavigate()
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
                onClick={() => {
                    navigate("/")
                }}
            >
                <Avatar alt="Logo" src={require("../img/logo.png")} />
            </IconButton>
            <div className="text primary2 bold large">
                Decision Support System for Sustainable Farm Management and Resource Optimization
            </div>
        </div>
    )
}


export function IconTitle({ title, icon, style, imgStyle, titleStyle }) {
    return (
        <div className='icon-bg' style={{ height: "100vh", ...style }}>
            <div className='icon-bg-content'>
                <img style={{ width: "70%", ...imgStyle }} src={icon}></img>
                <div className='icon-bg-msg' style={{ ...titleStyle }}>{title}</div>
            </div>
        </div>
    )
}



export function EmptyPage() {
    return (
        <IconTitle title={"Sorry, the page you're looking for doesn't exist !"} icon={require("../img/404.gif")} />
    )
}












export function getWeatherIcon(description) {
    const descriptionLower = description.toLowerCase()

    const weatherColors = {
        lightRain: '#1E88E5',
        moderateRain: '#1976D2',
        heavyRain: '#0D47A1',
        thunderstorm: '#D32F2F',
        sunny: '#FF9800',
        fewClouds: '#616161',
        brokenClouds: '#9E9E9E',
        scatteredClouds: '#FFC107',
        overcastClouds: '#757575',
        snow: '#B0BEC5',
        mist: '#757575',
        fog: '#90A4AE',
        showerRain: '#42A5F5',
    }

    if (descriptionLower.includes("rain")) {
        if (descriptionLower.includes("light")) {
            return <WiRainMix color={weatherColors.lightRain} />
        } else if (descriptionLower.includes("moderate")) {
            return <WiRain color={weatherColors.moderateRain} />
        } else if (descriptionLower.includes("shower")) {
            return <WiRain color={weatherColors.showerRain} />
        } else if (descriptionLower.includes("heavy")) {
            return <WiRain color={weatherColors.heavyRain} />
        } else {
            return <WiThunderstorm color={weatherColors.thunderstorm} />
        }
    } else if (descriptionLower.includes("clear") || descriptionLower.includes("sky")) {
        return <WiDaySunny color={weatherColors.sunny} />
    } else if (descriptionLower.includes("cloud")) {
        if (descriptionLower.includes("few")) {
            return <WiCloudy color={weatherColors.fewClouds} />
        } else if (descriptionLower.includes("broken")) {
            return <BsCloudSun color={weatherColors.brokenClouds} />
        } else if (descriptionLower.includes("scattered")) {
            return <BsCloudSun color={weatherColors.scatteredClouds} />
        } else if (descriptionLower.includes("overcast")) {
            return <WiCloud color={weatherColors.overcastClouds} />
        } else {
            return <WiCloud />
        }
    } else if (descriptionLower.includes("snow")) {
        return <WiSnow color={weatherColors.snow} />
    } else if (descriptionLower.includes("mist")) {
        return <WiFog color={weatherColors.mist} />
    } else if (descriptionLower.includes("fog")) {
        return <WiFog color={weatherColors.fog} />
    } else {
        return <WiDaySunny />
    }
}

export function getWeatherBackground  (description)  {
    const descriptionLower = description.toLowerCase();

    const weatherBackgrounds = {
        lightRain: 'lightRain.jpg',
        moderateRain: 'moderateRain.jpg',
        heavyRain: 'heavyRain.jpg',
        thunderstorm: 'thunderstorm.jpg',
        sunny: 'sunny.jpg',
        fewClouds: 'fewClouds.jpg',
        brokenClouds: 'brokenClouds.jpg',
        scatteredClouds: 'scatteredClouds.jpg',
        overcastClouds: 'overcastClouds.jpg',
        snow: 'snow.jpg',
        mist: 'mist.jpg',
        fog: 'fog.jpg',
        showerRain: 'showerRain.jpg',
    };

    if (descriptionLower.includes("rain")) {
        if (descriptionLower.includes("light")) {
            return weatherBackgrounds.lightRain;
        } else if (descriptionLower.includes("moderate")) {
            return weatherBackgrounds.moderateRain;
        } else if (descriptionLower.includes("shower")) {
            return weatherBackgrounds.showerRain;
        } else if (descriptionLower.includes("heavy")) {
            return weatherBackgrounds.heavyRain;
        } else {
            return weatherBackgrounds.thunderstorm;
        }
    } else if (descriptionLower.includes("clear") || descriptionLower.includes("sky")) {
        return weatherBackgrounds.sunny;
    } else if (descriptionLower.includes("cloud")) {
        if (descriptionLower.includes("few")) {
            return weatherBackgrounds.fewClouds;
        } else if (descriptionLower.includes("broken")) {
            return weatherBackgrounds.brokenClouds;
        } else if (descriptionLower.includes("scattered")) {
            return weatherBackgrounds.scatteredClouds;
        } else if (descriptionLower.includes("overcast")) {
            return weatherBackgrounds.overcastClouds;
        } else {
            return weatherBackgrounds.brokenClouds; 
        }
    } else if (descriptionLower.includes("snow")) {
        return weatherBackgrounds.snow;
    } else if (descriptionLower.includes("mist")) {
        return weatherBackgrounds.mist;
    } else if (descriptionLower.includes("fog")) {
        return weatherBackgrounds.fog;
    } else {
        return weatherBackgrounds.sunny;
    }
}

export const getStatusIcon = (status) => {
    switch (status) {
        case 'Pending':
            return <PendingIcon sx={{ fontSize: "2rem" }} />
        case 'In Progress':
            return <PendingActionsIcon sx={{ fontSize: "2rem" }} />
        case 'Completed':
            return <TaskIcon sx={{ fontSize: "2rem" }} />
        default:
            return <TaskIcon sx={{ fontSize: "2rem" }} />
    }
}

export const getStatusColor = (status) => {
    switch (status) {
        case 'Pending':
            return '#FFA726'
        case 'In Progress':
            return '#42A5F5'
        case 'Completed':
            return '#66BB6A'
        default:
            return '#BDBDBD'
    }
}

export const getPriorityColor = (priority) => {
    switch (priority) {
        case 'Low':
            return '#4CAF50'
        case 'Medium':
            return '#FFC107'
        case 'High':
            return '#F44336'
        default:
            return '#9E9E9E'
    }
}






export function ImageCard({ name, image }) {
    const [isHovered, setIsHovered] = useState(false);

    const cardStyles = {
        position: 'relative',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '60vh',
        width: '50vh',
        borderRadius: '15px',
        overflow: 'hidden',
        transition: '0.5s',
        display: 'flex',
        alignItems: 'flex-end',
        margin: "10px",
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        boxShadow: isHovered ? '0 4px 20px rgba(0, 0, 0, 0.5)' : 'none',
        cursor:"default"
    };

    const overlayStyles = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        background: isHovered ? 'linear-gradient(to top, #308f32b8, rgba(0, 0, 0, 0))' : 'linear-gradient(to top, #308f326e, rgba(0, 0, 0, 0))',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: "0.5s",
    };

    return (
        <Card
            style={cardStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div style={overlayStyles}>
                <div className="text primary xxlarge" style={{ color: 'white', textAlign: 'center' }}>
                    {name}
                </div>
            </div>
        </Card>
    );
}