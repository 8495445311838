import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserData, isAuthenticated } from "../Utils";
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { IconText, Slogan } from "./General";
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorElUser, setAnchorElUser] = React.useState(null)
    const [isShown, setisShown] = useState(false)
    const userData=getUserData()


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }


    const handleNavigation = useCallback((path) => {
        if (location.pathname === path) return


        navigate(path);
    }, [location.pathname, navigate])


    useEffect(() => {
        if (location.pathname.includes("/user")) {
            setisShown(false)
        } else {
            setisShown(true)
        }
    }, [location.pathname])


    if (isShown)
        return (
            <div className="header-container">
                <div className="header">
                    <Slogan/>
                    
                    <nav className="header-navbar">
                        <ul className="nav">
                            <li
                                onClick={() => handleNavigation("/")}
                                className={location.pathname === "/" ? "nav-item active" : "nav-item"}
                            >
                                <a className="nav-link">Home</a>
                            </li>



                            {isAuthenticated() ?
                                <li style={{ marginLeft:"10px" }}>
                                    <Tooltip title={userData.first_name+" "+userData.last_name}>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt="Avatar" src={(userData.avatar ? userData.avatar : userData.first_name)} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <MenuItem onClick={() => {
                                            handleCloseUserMenu()
                                            navigate("/user")
                                        }}>
                                            <IconText icon={<DashboardIcon style={{ marginRight: "10px" }} />} text={"Dashboard"} />
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            handleCloseUserMenu()
                                            localStorage.clear()
                                            navigate("/login")
                                        }}>
                                            <IconText icon={<LogoutIcon style={{ marginRight: "10px" }} />} text={"Logout"} />
                                        </MenuItem>
                                    </Menu>
                                </li>
                                :
                                <li
                                    onClick={() => handleNavigation("/login")}
                                    className={location.pathname === "/login" ? "nav-item active" : "nav-item"}
                                >
                                    <a className="nav-link">Login</a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        );
}
